import React from 'react';

import Post from './post';

function PostList(props){

    const {lindyDb}  = props

    return(
        <>
        <section className="content">
            <div className="content-post">
            {
                lindyDb.map( article =>{
                return <Post key={article.id} article={article} />
                })
            }
            </div>
        </section>
        </>
    )
}

export default PostList;


