import React, {useState, useEffect} from 'react';
import axios from 'axios';
import * as Yup from "yup";
import formSchema from './components/formSchema';

import './App.scss';


//components 
import EmailSignup from './components/emailSignup'
import PostList from './components/postList';

const {REACT_APP_API_KEY , REACT_APP_DATABASE_URL, REACT_APP_EMAILSDB_URL} = process.env;

console.log(REACT_APP_API_KEY);
console.log(REACT_APP_DATABASE_URL);
console.log(REACT_APP_EMAILSDB_URL);
const initialPostDb = [{
  id:'',
  fields:{
    Title: '',
    Author: '',
    Url:''
  }
}]

const initialFormValues ={
  fields:{
      name: "",
      email: ""
  }
}

const initialErrors ={
  email: "",
  name: ""
}

function App() {

  const [lindyDb, setLindyDb] = useState(initialPostDb);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialErrors);
  const [success, setSuccess] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)

  useEffect(() => {
    formSchema.isValid(formValues.fields).then(valid => {
      setButtonDisabled(!valid);
    });
  }, [formValues]);

  
  // Save Emails
  function submitHandler(e){
    e.preventDefault();
    axios.post( REACT_APP_EMAILSDB_URL, formValues,{
      headers: {
        Authorization: REACT_APP_API_KEY
      }
    })
      .then(res=>{
        setFormValues(initialFormValues)
        setSuccess(true)
      })
      .catch(err =>{
      console.log(err);
      })
      .finally(()=>{
      })
  }

  function changeHandler(e){
    const {name, value} = e.target;

    Yup
      .reach(formSchema, name)
      //we can then run validate using the value
      .validate(value)
      // if the validation is successful, we can clear the error message
      .then(valid => {
        setErrors({
          ...errors,
          [name]: ""
        });
      })
      .catch(err => {
        setErrors({
          ...errors,
          [name]: err.errors[0]
        });
      });

    setFormValues({
      ...formValues,
      fields: {
        ...formValues.fields,
        [name]: value
      }
    })
  }

  // render content
  useEffect(()=>{
    axios.get(REACT_APP_DATABASE_URL, {
      headers: {
        Authorization: REACT_APP_API_KEY,
      }
    })
	    .then( res =>{
      setLindyDb(res.data.records);
	  })
      .catch( err => {
        console.log(err);
      })
  },[])

  


  return (
    <>
     <div className="container"> 
        <EmailSignup 
          submitHandler={submitHandler}
          changeHandler={changeHandler} 
          buttonDisabled={buttonDisabled}
          formValues={formValues}
          errors={errors}
          success={success}/>
        <PostList lindyDb={lindyDb} />
     </div>
    </>
  );
}

export default App;
