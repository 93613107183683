import React from 'react';

import EmailForm from './emailForm'

function EmailSignup(props){

    const {submitHandler,
        changeHandler, 
        formValues, 
        buttonDisabled, 
        errors, 
        success} = props

    return(
            <section className="header">
                <div className="header-content">
                    <h1>The Lindy Library</h1>
                    <h2>We're compiling the 0.1% of resources that will give you 90% of the value</h2>
                    <h3>We send out a monthly summary of new content. If you think something is missing on the list, <a className='twitter-link'href="https://twitter.com/thelindylibrary">let us know on twitter</a>.</h3>
                    <EmailForm 
                        submitHandler={submitHandler}
                        changeHandler={changeHandler}
                        formValues={formValues}
                        buttonDisabled={buttonDisabled}/>
                    {errors.name ? (<p className="error">{errors.name}</p>) : null}
                    {errors.email ? (<p className="error">{errors.email}</p>) : null}
                    {success && (<p className="error">Thank you for signing up to our newsletter. <br/> If you ever need to reach us you can find us on twitter <a className='twitter-link-err'href="https://twitter.com/thelindylibrary">@thelindylibrary</a> </p>)}
                </div>
                
            </section>
    )
}


export default EmailSignup;