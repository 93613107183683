import * as Yup from "yup";

const formSchema = Yup.object().shape({
    email: Yup
      .string()
      .email("We can´t send emails to that address, please make sure its a valid email address.")
      .required("We can´t send you email without a email address."),
    name: Yup
      .string()
      .min(1, "Please add your name, we promise we won´t spam you")
      .required("Name is Required")
  });

  export default formSchema