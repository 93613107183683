import React from 'react';


function Post({article}){

    console.log(article.fields.Author === undefined);
    

    return(
        <>
        <li>
            { article.fields.Author
                ? <a href={article.fields.Url} rel="noopener noreferrer" target="_blank">{article.fields.Title} - {article.fields.Author}</a>
                : <a href={article.fields.Url} rel="noopener noreferrer" target="_blank">{article.fields.Title}</a>
            }
			
		</li>
        </>
    )
}

export default Post;