import React from 'react'

function EmailForm(props){

    const {submitHandler, changeHandler, formValues, buttonDisabled} = props

    return(

        <form action="" onSubmit={submitHandler}>
            
            <input 
                type="text" 
                name="name" 
                id="" 
                placeholder="Name"
                value={formValues.fields.name}
                onChange={changeHandler}/>
            
            
                <input 
                    type="email" 
                    name="email" 
                    id="" 
                    placeholder="Email Address"
                    value={formValues.fields.email}
                    onChange={changeHandler}/>
           
            <button disabled={buttonDisabled}>Sign Up</button>
        </form>
    )
}

export default EmailForm;